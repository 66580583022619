<script setup>
  import { ref, computed } from 'vue';
  import moment from 'moment';
  import { hasUserPlayerRights, isUserAdmin, elo } from "../entrypoints/utils.js";
  import api from '../entrypoints/api.js'
  import { useToast } from 'primevue/usetoast';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import axios from 'axios';

  import TournamentUserButtons from "./TournamentUserButtons.vue";
  import ParticipantRegistration from "./ParticipantRegistration.vue";
  import AdminActionsMenu from './AdminActionsMenu.vue';
  import RegistrationMenu from './RegistrationMenu.vue';

  const toast = useToast();
  const { t } = useI18n();
  const store = useStore();

  const tournament = computed(() => store.getters.getTournament)
  const initTime = computed(() => seconds2time(tournament.value.time_initial));
  const perMoveTime = computed(() => seconds2time(tournament.value.time_per_move));
  const participantsShowStateIndex = ref(0);
  const participantsShowStates = ['all', 'unchecked', 'checked'];
  const participantsShowState = computed(() => participantsShowStates[participantsShowStateIndex.value])
  const searchParticipant = ref(null);

  function toggleParticipantsShowState() {
    participantsShowStateIndex.value = (participantsShowStateIndex.value + 1) % participantsShowStates.length
  }

  const shownParticipants = computed(() => {
    let searchedParticipants = [];

    if (searchParticipant.value) {
      const regex = new RegExp(searchParticipant.value, 'i');
      let exactMatches = [];
      let startMatches = [];
      let partialMatches = [];

      participantsOrdered.value.forEach(participant => {
        const exactMatch = participant.eloRank === Number(searchParticipant.value) ||
        elo(participant, tournament.format) === Number(searchParticipant.value);

        const startMatch = participant.first_name?.toLowerCase().startsWith(searchParticipant.value.toLowerCase()) ||
        participant.last_name?.toLowerCase().startsWith(searchParticipant.value.toLowerCase()) ||
        elo(participant, tournament.value.format)?.toString().startsWith(searchParticipant.value) ||
        participant.eloRank?.toString().startsWith(searchParticipant.value)

        if (exactMatch) {
          exactMatches.push(participant);
        } else if (startMatch) {
          startMatches.push(participant);
        } else if (regex.test(`${participant.first_name} ${participant.last_name} ${elo(participant, tournament.format)} ${participant.eloRank}`)) {
          partialMatches.push(participant);
        }
      });

      searchedParticipants = [...exactMatches, ...startMatches, ...partialMatches];
    } else {
      searchedParticipants = participantsOrdered.value;
    }

    return searchedParticipants.filter(participant => {
      if (participantsShowState.value === "all" || participantsShowState.value === "checked" && participant.checkin === true || participantsShowState.value === "unchecked" && participant.checkin === false) {
        return participant
      }
    });
  })

  const participantsOrdered = computed(() => {
    const sortedParticipants = [...tournament.value.participants.sort((a, b) => {
      if (elo(a, tournament.value.format) === elo(b, tournament.value.format)) {
        if (a.last_name === b.last_name || a.last_name == null) {
          return a.first_name.localeCompare(b.first_name);
        }
        return a.last_name.localeCompare(b.last_name);
      }
      return elo(b, tournament.value.format) - elo(a, tournament.value.format);
    })];
    sortedParticipants.forEach((participant, index) => participant.eloRank = index + 1);
    return sortedParticipants;
  })

  function getParticipantEloRank() {
    return tournament.value.participants.find(participant => participant == store.getters.getUserParticipant).eloRank;
  }

  async function toggleParticipantActivation(participant, type) {
    if (!participant.checkin && type === "referee") {
      axios.put(`/api/participants/${participant.id}/toggle_checkin`)
      .then(response => {
      })
      .catch(error => {
        toast.add({ severity: "error", summary: error.response.data.message, life: 3000 })
      })
    } else {
      const response = await api.toggleParticipantActivation(participant);
      if(response.error.value) {
        toast.add({ severity: "error", summary: response.error.value.response.data.message, life: 3000 });
      }
    }
  }

  async function deleteParticipant(participant) {
    const response = await api.deleteParticipant(participant)

    if (!response.error.value) {
      toast.add({ severity: 'success', summary: `Player ${participant.first_name} ${participant.last_name != null ? participant.last_name : ""} deleted`, life: 3000 });
    }
    if (response.error.value) {
      toast.add({ severity: 'error', summary: response.error.value.response.data.message, life: 3000 });
    }
  }

  function seconds2time(seconds) {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }
</script>

<template>
  <div data-index="infos">
    <!-- Tournament details -->
    <div class="bg-greyblue p-4 rounded-2xl">
      <div class="mb-3 flex justify-between items-center">
        <h1 class="text-3xl font-bold">{{ tournament.title }}</h1>
        <div class="flex gap-2">
          <RouterLink :to="`/tournaments/${tournament.id}/edit`"
          v-if="isUserAdmin(tournament)">
            <button class="primary-btn">
              <i class="fa-solid fa-gear"></i>
              <p class="hidden sm:inline-block ml-2">{{ $t('config') }}</p>
            </button>
          </RouterLink>
        </div>
      </div>

      <div class="flex flex-col mb-4 gap-3">
        <div class="flex flex-wrap gap-3 sm:gap-8">
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-calendar-day"></i>
            <p class="m-0 font-bold">{{ moment(tournament.date).format('DD/MM/YYYY') }}</p>
            <p v-show="tournament.end_date" class="m-0 font-bold"> - </p>
            <p v-show="tournament.end_date" class="m-0 font-bold">{{ moment(tournament.end_date).format('DD/MM/YYYY') }}</p>
          </div>
          <div class="flex gap-2 items-center">
            <i class="fa-regular fa-clock"></i>
            <p class="m-0 font-bold">{{ moment(tournament.date).format('HH:mm') }}</p>
          </div>
        </div>
        <div class="flex flex-wrap gap-3 sm:gap-8">
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-people-arrows"></i>
            <p class="m-0 font-bold">{{ $t(`tournament.form.${tournament.system}`) || "--" }}</p>
          </div>
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-circle-notch"></i>
            <p class="m-0 font-bold">{{ tournament.max_rounds || "--" }}</p>
          </div>
        </div>
        <div class="flex flex-wrap gap-3 sm:gap-8">
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-forward"></i>
            <p class="m-0 font-bold" v-t="`tournament.form.${tournament.format}`"></p>
          </div>
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-stopwatch"></i>
            <div class="flex gap-1">
              <p v-if="tournament.time_initial == null" class="m-0 font-bold">--</p>
              <div v-else class="flex gap-1">
                <p v-if="initTime.days" class="m-0 font-bold">{{ initTime.days }}d</p>
                <p v-if="initTime.hours" class="m-0 font-bold">{{ initTime.hours }}h</p>
                <p v-if="initTime.minutes" class="m-0 font-bold">{{ initTime.minutes }}m</p>
                <p v-if="initTime.seconds || tournament.time_initial == 0" class="m-0 font-bold">{{ initTime.seconds }}s</p>
              </div>
              <p class="m-0 font-bold">+</p>
              <p v-if="tournament.time_per_move == null" class="m-0 font-bold">--</p>
              <div v-else class="flex gap-1">
                <p v-if="perMoveTime.days" class="m-0 font-bold">{{ perMoveTime.days }}d</p>
                <p v-if="perMoveTime.hours" class="m-0 font-bold">{{ perMoveTime.hours }}h</p>
                <p v-if="perMoveTime.minutes" class="m-0 font-bold">{{ perMoveTime.minutes }}m</p>
                <p v-if="perMoveTime.seconds || tournament.time_per_move == 0" class="m-0 font-bold">{{ perMoveTime.seconds }}s</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-2 items-center">
          <i class="fa-solid fa-location-dot"></i>
          <p class="m-0 font-bold">{{ tournament.address || "--" }}</p>
        </div>
        <div class="flex gap-2 items-center">
          <i class="fa-solid fa-user-tie"></i>
          <p v-if="tournament.admin" class="m-0 font-bold">{{ tournament.admin?.first_name }} {{ tournament.admin?.last_name }}</p>
          <p v-else class="m-0 font-bold">--</p>
        </div>
      </div>

      <Panel class="whitespace-pre-line" :header="$t('tournament.form.descriptionLabel')" :collapsed="true" toggleable>
        {{ tournament.description }}
      </Panel>
    </div>

    <!-- User action -->
    <TournamentUserButtons @toggleParticipant="(participant) => toggleParticipantActivation(participant, 'self')" />

    <!-- Participants list -->
    <div id="participants-table"
    class="bg-greyblue p-4 rounded-2xl mt-3 min-h-1/3">

      <div class="flex items-center justify-between mb-3">
        <h2 class="mb-0 font-bold text-2xl">{{ $t('Participants') }} - <span id="participants-count">{{ tournament.participants.length }} / {{ tournament.max_participants }}</span></h2>

        <div class="flex items-center gap-4"
        v-if="hasUserPlayerRights(tournament)">
          <RegistrationMenu />

          <AdminActionsMenu />
        </div>
      </div>

      <div v-if="store.getters.getUserParticipant"
      class="w-full flex justify-center mb-6">
        <div class="w-full max-w-md" :key="store.getters.getUserParticipant.id">
          <ParticipantRegistration
          @toggleActivation="toggleParticipantActivation(store.getters.getUserParticipant, 'referee')"
          @deleteParticipant="deleteParticipant(store.getters.getUserParticipant)"
          :participant="store.getters.getUserParticipant" :eloRank="getParticipantEloRank()" />
        </div>
      </div>

      <div class="flex justify-center items-center gap-6 mb-4">
        <input v-model="searchParticipant" type="text" class="bg-deep-greyblue rounded-md" :placeholder="`${$t('participantSearchPlaceholder')}...`">
        <div class="flex items-center gap-1">
          <button @click="toggleParticipantsShowState" class="primary-btn">{{ $t(`tournament.infos.participantsShowState.${participantsShowState}`) }}</button>
        </div>
      </div>

      <div id="participants-list" class="flex flex-wrap gap-5 justify-center">
        <div :key="participant.id" id="participant.id"
        class="w-full max-w-md"
        v-for="participant in shownParticipants">

          <ParticipantRegistration
            @toggleActivation="toggleParticipantActivation(participant, 'referee')"
            @deleteParticipant="deleteParticipant(participant)"
            :participant="participant" :eloRank="participant.eloRank" />
        </div>
      </div>
    </div>
  </div>
</template>
