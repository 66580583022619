<script setup>
  import { ref, computed } from 'vue'
  import { useToast } from 'primevue/usetoast';
  import { useStore } from 'vuex';
  import { hasUserRoundRights, isRoundMissingResults } from "../entrypoints/utils.js";
  import api from '../entrypoints/api.js';

  const props = defineProps(['visibleRoundIndex'])

  const store = useStore();
  const toast = useToast();

  const pendingCreateRound = ref(false);
  const isLastRoundMissingResults = computed(() => {
    if (store.getters.getCurrentRound) {
      return !!isRoundMissingResults(store.getters.getCurrentRound);
    }
    return false
  });

  async function createRound(params) {
    pendingCreateRound.value = true;
    const response = await api.createRound(store.getters.getTournament.id, params);
    pendingCreateRound.value = false;

    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.response.data.message, life: 3000 });
    } else {
      toast.add({ severity: 'success', summary: `New round created`, life: 3000 });
    }
  }
</script>

<template>
  <div v-if="hasUserRoundRights(store.getters.getTournament) && store.getters.getTournament.status != 'over'">
    <button v-show="!pendingCreateRound" class="primary-btn flex gap-2 items-center disabled:opacity-40 disabled:pointer-events-none"
    :disabled="visibleRoundIndex > 0 && isLastRoundMissingResults"
    @click="createRound({})">
      <i class="fa-solid fa-plus"></i>
      <p class="hidden md:inline-block" v-t="'newRound'"></p>
    </button>
    <ProgressSpinner v-show="pendingCreateRound" style="width:50px; height:50px" strokeWidth="4" />
  </div>
</template>
