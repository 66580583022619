<script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useConfirm } from "primevue/useconfirm";
  import { computeParticipantPaired } from "../entrypoints/utils.js";
  import { useI18n } from 'vue-i18n';
  import { useToast } from 'primevue/usetoast';
  import api from '../entrypoints/api.js';

  const store = useStore();
  const confirm = useConfirm();
  const toast = useToast();
  const { t } = useI18n();

  const emit = defineEmits(['toggleParticipant']);

  const isParticipantPaired = computed(() => {
    return store.getters.getUserParticipant ? computeParticipantPaired(store.getters.getTournament.rounds, store.getters.getUserParticipant?.id) : false;
  })
  const isNotificationsPermitted = ref('Notification' in window ? Notification.permission === "granted" : null)

  const isUserAdmin = computed(() => {
    if (!store.getters.getUser)
    return false

    return store.getters.getUser.id === store.getters.getTournament.admin.id;
  });
  const isDisabled = computed(() => {
    return store.getters.getTournament.participants.length >= store.getters.getTournament.max_participants ||
      store.getters.getTournament.open_registrations == false && !isUserAdmin.value;
  });
  const remainingHalfByes = computed(() => {
    return store.getters.getTournament.max_half_byes - store.getters.getUserParticipant?.half_byes_count
  })
  const remainingFullByes = computed(() => {
    return store.getters.getTournament.max_full_byes - store.getters.getUserParticipant?.full_byes_count
  })

  function toggleSelfActivation() {
    emit('toggleParticipant', store.getters.getUserParticipant);
  }

  async function selfRegister() {
    const response = await api.joinTournament(store.getters.getTournament.id);

    if (response.error) {
      toast.add({ severity: "error", summary: response.error.response.data.message, life: 3000 })
    } else {
      toast.add({ severity: "success", summary: "You are registered to this tournament", life: 3000 })
    }
  }

  async function selfUnregister() {
    const response = await api.leaveTournament(store.getters.getTournament.id);

    if (response.error.value) {
      toast.add({ severity: "error", summary: response.error.value.response.data.message, life: 3000 })
    } else {
      toast.add({ severity: "success", summary: "You are unregistered", life: 3000 })
    }
  }

  const confirmSelfUnregister = () => {
    confirm.require({
      header: t('confirm.leaveTournamentHeader'),
      message: t('confirm.leaveTournamentMessage'),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.leave'),
      accept: () => selfUnregister(),
      group: 'dialog',
    })
  }

  async function askNotifications() {
    if ('Notification' in window) {
      if (Notification.permission === 'denied') {
        alert('errors.notificationsBlocked');
      } else if (Notification.permission === 'granted') {
        handleWebpushSubscription()
      } else {
        Notification.requestPermission().then(async permission => {
          if (permission === "granted") {
            handleWebpushSubscription()
            isNotificationsPermitted.value = true;
          }
        })
      }
    } else if (!('Notification' in window)) {
      alert(t('errors.notificationsUnsupported'));
    }
  }

  async function handleWebpushSubscription() {
    api.subscribeWebpush(store.getters.getAuthToken, store.getters.getUserParticipant?.id || null)
  }
</script>

<template>
  <div>
    <div id="player-btn" class="bg-greyblue p-4 rounded-2xl mt-3"
    v-if="store.getters.getTournament.status != 'over'">
      <h2 class="text-2xl font-bold mb-2.5" v-t="'playerPanelTitle'"></h2>
      <div class="flex flex-col gap-4">
        <!-- Join tournament btn -->
        <div v-if="!store.getters.isAuthenticated || !store.getters.getUserParticipant">
          <h5 class="mb-2" v-if="!isDisabled" v-t="'joinMessage'"></h5>
          <h5 v-else
          :class="{ 'opacity-40': isDisabled }"
          class="text-red" v-t="'registrationsClosedMessage'"></h5>

          <button v-if="store.getters.isAuthenticated || isDisabled"
          class="primary-btn"
          :class="{ 'opacity-40': isDisabled }"
          :disabled="isDisabled"
          @click="selfRegister"
          v-t="'joinBtn'">
          </button>
          <button v-else-if="!store.getters.isAuthenticated && !isDisabled"
          class="primary-btn"
          @click="() => $router.push({ path: '/login', query: { redirect: $route.fullPath }})"
          v-t="'users.login'">
          </button>
        </div>

        <!-- Leave tournament btn -->
        <div v-else-if="!isParticipantPaired">
          <h5 class="mb-2" v-t="'unregisterMessage'"></h5>
          <button class="warning-btn"
          @click="confirmSelfUnregister"
          v-t="'unregisterBtn'">
          </button>
        </div>

        <!-- Toggle Activation -->
        <div :class="{'opacity-40': !store.getters.getUserParticipant || !store.getters.isAuthenticated}">
          <h5 class="mb-2" v-t="'deactivateMessage'" v-show="store.getters.getUserParticipant && !store.getters.getUserParticipant.active"></h5>
          <h5 class="mb-2" v-t="'activateMessage'" v-show="store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active"></h5>
          <h5 class="font-bold text-danger mb-2" v-show="store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active" v-t="'uncheckMessage'"></h5>
          <button class="flex items-center w-20 h-8 rounded-full border-2 shadow-lg bg-opacity-40"
          :class="{
            'border-danger bg-danger justify-end': store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
            'border-success bg-success justify-end': store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
            'border-light-grey bg-light-grey': !store.getters.getUserParticipant || !store.getters.getUserParticipant.active,
          }"
          :disabled="!store.getters.getUserParticipant"
          @click="toggleSelfActivation">
            <div class="opacity-100 rounded-full h-5 w-5 mx-1"
            :class="{
              'bg-danger': store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
              'bg-success': store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
              'bg-light-grey': !store.getters.getUserParticipant || !store.getters.getUserParticipant.active,
            }"></div>
          </button>
        </div>
        <div v-if="store.getters.getUserParticipant">
          <h1 class="text-lg">
            <strong :class="{
              'text-danger': remainingHalfByes === 0,
              'text-success': remainingHalfByes > 0,
            }">{{ remainingHalfByes }}</strong> {{ $t('tournament.infos.registration.halfByesRemaining') }}</h1>
          <h1 class="text-lg">
            <strong :class="{
              'text-danger': remainingFullByes === 0,
              'text-success': remainingFullByes > 0,
            }">{{ remainingFullByes }}</strong> {{ $t('tournament.infos.registration.fullByesRemaining') }}</h1>
        </div>
        <div v-if="!isNotificationsPermitted">
          <h5 v-t="'notifyMessage'"></h5>
          <button @click="askNotifications"
          class="primary-btn"
          v-t="'notifyBtn'">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
