<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useToast } from 'primevue/usetoast';
  import axios from 'axios';

  const toast = useToast();
  const store = useStore();
  const router = useRouter();

  const user = computed(() => store.getters.getUser );

  const isEditMode = ref(false);
  const isLoading = ref(false);
  const fieldErrors = ref({})
  const userForm = ref({
    first_name: user.value.first_name,
    last_name: user.value.last_name,
    elo_standard: user.value.elo_standard,
    elo_rapid: user.value.elo_rapid,
    elo_blitz: user.value.elo_blitz,
    id_ffe: user.value.id_ffe,
  })
  const passwordForm = ref({
    current_password: '',
    password: '',
    password_confirmation: '',
  })
  const emailForm = ref({
    email: '',
    current_password: '',
  })

  const isFfeIdentified = computed(() => {
    return userForm.value.id_ffe !== ''
  })

  function toggleEditMode() {
    isEditMode.value = !isEditMode.value;
  }

  function handleUpdateUser() {
    isLoading.value = true;
    fieldErrors.value = {};

    axios
      .put('/users', { user: userForm.value })
      .then((response) => {
        store.dispatch('loginUserWithToken', { auth_token: localStorage.getItem("auth_token") });
        isEditMode.value = !isEditMode.value;

        toast.add({ severity: 'success', summary: `Profile successfully updated`, life: 3000 });

        userForm.value = {
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          elo_standard: response.data.user.elo_standard,
          elo_rapid: response.data.user.elo_rapid,
          elo_blitz: response.data.user.elo_blitz,
          id_ffe: response.data.user.id_ffe,
        }
      })
      .catch((err) => {
        fieldErrors.value = err.response?.data?.errors || {};
        if (err.response.data.message)
          toast.add({ severity: 'error', summary: err.response.data.message, life: 3000 });
      })
      .finally(() => {
        isLoading.value = false;
      })
  }

  function handleChangeEmail() {
    isLoading.value = true;
    fieldErrors.value = {};

    axios
      .put('/users', {
        user: {
          email: emailForm.value.email,
          current_password: emailForm.value.current_password,
        }
      })
      .then((response) => {
        emailForm.value.email = '';
        emailForm.value.current_password = '';
        toast.add({ severity: 'success', summary: `Email successfully updated`, life: 3000 });
      })
      .catch((err) => {
        if(err.response && err.response.data.errors) {
          fieldErrors.value = err.response?.data?.errors || {};
        } else {
          toast.add({ severity: 'error', summary: `Unexpected error`, life: 3000 });
        }
      })
      .finally(() => {
        isLoading.value = false;
      })
  }

  function handleChangePassword() {
    isLoading.value = true;
    fieldErrors.value = {};

    axios
      .put('/users', { user: passwordForm.value })
      .then((response) => {
        passwordForm.value.current_password = '';
        passwordForm.value.password = '';
        passwordForm.value.password_confirmation = '';
        toast.add({ severity: 'success', summary: `Password successfully updated`, life: 3000 });
      })
      .catch((err) => {
        if(err.response && err.response.data.errors) {
          fieldErrors.value = err.response?.data?.errors || {};
        } else {
          toast.add({ severity: 'error', summary: `Unexpected error`, life: 3000 });
        }
      })
      .finally(() => {
        isLoading.value = false;
      })
  }

  function handleLogout() {
    store.dispatch('logoutUser');
    router.push('/login');
  }
</script>

<template>
  <div class="lg:flex min-h-full justify-center px-6 py-12 lg:px-8 gap-10">
    <div class="sm:mx-auto sm:w-full sm:max-w-lg">
      <TabView>
        <TabPanel :header="$t('dashboard.profile')">
          <div v-show="!isEditMode">
            <div v-if="user">
              <div class="flex justify-between items-center mb-6">
                <h2 class="text-2xl">{{ $t('dashboard.greetings') }}, {{user.first_name}} {{user.last_name}} !</h2>
              </div>
              <div>
                <h3 class="text-xl mb-2" v-t="'rating'"></h3>
                <p>{{ $t('tournament.form.standard') }}: {{ user.elo_standard }}</p>
                <p>{{ $t('tournament.form.rapid') }}: {{ user.elo_rapid }}</p>
                <p>{{ $t('tournament.form.blitz') }}: {{ user.elo_blitz }}</p>
              </div>

              <div class="flex justify-between mt-5">
                <button @click="handleLogout" class="danger-btn" v-t="'logout'"></button>
                <button class="button primary-btn" @click="toggleEditMode" v-t="'edit'"></button>
              </div>
            </div>
          </div>

          <div v-show="isEditMode">
            <button class="secondary-btn w-full mb-6" @click="toggleEditMode" v-t="'nav.back'"></button>
            <form @submit.prevent="handleUpdateUser">
              <div class="flex flex-col gap-4 mb-10">
                <div class="flex flex-col gap-2 w-full">
                  <label class="text-light-grey" v-t="'ffeId'"></label>
                  <InputText id="id_ffe" v-model="userForm.id_ffe" class="p-2" />
                  <div v-if="fieldErrors.id_ffe" class="text-sm text-red-500">{{ fieldErrors.id_ffe[0] }}</div>
                </div>
                <div class="flex flex-col sm:flex-row justify-between gap-2">
                  <div class="flex flex-col gap-2 w-full">
                    <label class="text-light-grey" v-t="'firstName'"></label>
                    <InputText id="first_name" v-model="userForm.first_name" class="p-2" :disabled="isFfeIdentified" />
                    <div v-if="fieldErrors.first_name" class="text-sm text-red-500">{{ fieldErrors.first_name[0] }}</div>
                  </div>
                  <div class="flex flex-col gap-2 w-full">
                    <label class="text-light-grey" v-t="'lastName'"></label>
                    <InputText id="last_name" v-model="userForm.last_name" class="p-2" :disabled="isFfeIdentified" />
                    <div v-if="fieldErrors.last_name" class="text-sm text-red-500">{{ fieldErrors.last_name[0] }}</div>
                  </div>
                </div>
                <div class="flex flex-col gap-2 w-full">
                  <label for="rating-standard" class="text-light-grey" v-t="'tournament.form.standard'"></label>
                  <InputText type="number" id="rating-standard" v-model="userForm.elo_standard" class="p-2" :disabled="isFfeIdentified" />
                  <div v-if="fieldErrors.email" class="text-sm text-red-500">{{ fieldErrors.email[0] }}</div>
                </div>
                <div class="flex flex-col gap-2 w-full">
                  <label for="rating-rapid" class="text-light-grey" v-t="'tournament.form.rapid'"></label>
                  <InputText type="number" id="rating-rapid" v-model="userForm.elo_rapid" class="p-2" :disabled="isFfeIdentified" />
                  <div v-if="fieldErrors.email" class="text-sm text-red-500">{{ fieldErrors.email[0] }}</div>
                </div>
                <div class="flex flex-col gap-2 w-full">
                  <label for="rating-blitz" class="text-light-grey" v-t="'tournament.form.blitz'"></label>
                  <InputText type="number" id="rating-blitz" v-model="userForm.elo_blitz" class="p-2" :disabled="isFfeIdentified" />
                  <div v-if="fieldErrors.email" class="text-sm text-red-500">{{ fieldErrors.email[0] }}</div>
                </div>
                <button class="primary-btn" v-t="'nav.save'"></button>
              </div>
            </form>
          </div>
        </TabPanel>

        <TabPanel :header="$t('users.password')">
          <h1 class="text-xl font-bold mb-3" v-t="'users.changePassword'"></h1>
          <form @submit.prevent="handleChangePassword">
            <div class="flex flex-col gap-4">
              <div class="flex flex-col gap-2 w-full mb-6">
                <label class="text-light-grey" v-t="'users.currentPassword'"></label>
                <InputText id="current-password" type="password" v-model="passwordForm.current_password" class="p-2" autocomplete="current-password" required />
                <div v-if="fieldErrors.current_password" class="text-sm text-red-500">{{ fieldErrors.current_password[0] }}</div>
              </div>
              <div class="flex flex-col gap-2 w-full">
                <label class="text-light-grey" v-t="'users.newPassword'"></label>
                <InputText id="new-password" type="password" v-model="passwordForm.password" class="p-2" autocomplete="new-password" required />
                <div v-if="fieldErrors.password" class="text-sm text-red-500">{{ fieldErrors.password[0] }}</div>
              </div>
              <div class="flex flex-col gap-2 w-full">
                <label class="text-light-grey" v-t="'users.confirmationPassword'"></label>
                <InputText id="confirmation-password" type="password" v-model="passwordForm.password_confirmation" class="p-2" autocomplete="new-password" required />
                <div v-if="fieldErrors.password_confirmation" class="text-sm text-red-500">{{ fieldErrors.password_confirmation[0] }}</div>

              </div>
              <button class="warning-btn" v-t="'users.changePassword'"></button>
            </div>
          </form>
        </TabPanel>

        <TabPanel :header="$t('email')">
          <h1 class="text-xl font-bold mb-3" v-t="'users.changeEmail'"></h1>
          <h2 class="text-md flex gap-2 mb-3">
            <p class="font-bold">{{ $t('users.currentEmail') }}:</p>
            <p>{{ user.email }}</p>
          </h2>
          <form @submit.prevent="handleChangeEmail">
            <div class="flex flex-col gap-4">
              <div class="flex flex-col gap-2 w-full">
                <label class="text-light-grey" v-t="'users.newEmail'"></label>
                <InputText id="email" type="email" v-model="emailForm.email" class="p-2" autocomplete="email" required />
                <div v-if="fieldErrors.email" class="text-sm text-red-500">{{ fieldErrors.email[0] }}</div>
              </div>
              <div class="flex flex-col gap-2 w-full">
                <label class="text-light-grey" v-t="'users.currentPassword'"></label>
                <InputText id="current-password" type="password" v-model="emailForm.current_password" class="p-2" autocomplete="current-password" required />
                <div v-if="fieldErrors.current_password" class="text-sm text-red-500">{{ fieldErrors.current_password[0] }}</div>
              </div>
              <p class="italic">{{ $t('users.newEmailMessage') }}</p>
              <button class="warning-btn" v-t="'users.changeEmail'"></button>
            </div>
          </form>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
