<script setup>
  import { ref, computed } from 'vue'
  import { useConfirm } from "primevue/useconfirm";
  import { useI18n } from 'vue-i18n';
  import { useToast } from 'primevue/usetoast';
  import { useStore } from 'vuex';
  import { hasUserRoundRights } from "../entrypoints/utils.js";
  import api from '../entrypoints/api.js';

  const props = defineProps(['visibleRoundIndex', 'roundDisplayed'])

  const store = useStore();
  const toast = useToast();
  const confirm = useConfirm();
  const { t } = useI18n();

  const pendingDeleteRound = ref(false);
  const isButtonEnabled = computed(() => props.visibleRoundIndex > 0 && (props.visibleRoundIndex == store.getters.getCurrentRound?.index || props.roundDisplayed.status == 'draft'))

  function confirmDeleteRound(event) {
    confirm.require({
      target: event.currentTarget,
      header: t('confirm.deleteRoundHeader'),
      message: t('confirm.deleteRoundMessage', {roundIndex: props.visibleRoundIndex}),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.deleteRound', {roundIndex: props.visibleRoundIndex}),
      accept: deleteRound,
      group: 'dialog',
    });
  }

  async function deleteRound() {
    pendingDeleteRound.value = true;
    const deleteRoundIndex = props.roundDisplayed.index;
    const response = await api.deleteRound(props.roundDisplayed.id)

    pendingDeleteRound.value = false;
    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.response.data.message, life: 3000 })
    } else {
      toast.add({ severity: 'success', summary: `Round ${deleteRoundIndex} deleted`, life: 3000 })
    }
  }
</script>

<template>
  <div v-if="hasUserRoundRights(store.getters.getTournament) && store.getters.getTournament.status != 'over'">
    <button @click="confirmDeleteRound"
    v-show="!pendingDeleteRound"
    :disabled="!isButtonEnabled"
    class="danger-btn flex gap-2 items-center disabled:opacity-40 disabled:pointer-events-none">
      <i class="fa-solid fa-trash-can"></i>
      <p class="hidden md:inline-block" v-t="'delete'"></p>
    </button>
    <ProgressSpinner v-show="pendingDeleteRound" style="width:50px; height:50px" strokeWidth="4" />
  </div>
</template>
