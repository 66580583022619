<script setup>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  import { elo } from "../entrypoints/utils.js";

  const props = defineProps({
    participant: Object,
  });
  const store = useStore();

  const selfPairings = computed(() => {
    let previousScore = 0;

    return store.getters.getTournament.rounds.map((round) => {
      const pairing = round.pairings.find(pairing => pairing.white_player_id === props.participant?.id || pairing.black_player_id === props.participant?.id );
      let pairingClone = {}

      if (pairing) {
        pairingClone = { ...pairing };
        pairingClone.color = pairingClone.white_player_id === props.participant?.id ? "white" : "black";
        pairingClone.resultScore = pairingClone.result.includes(`${pairingClone.color}_win`) ? 1 : pairingClone.result === "draw" ? 0.5 : pairingClone.result === "pending" ? 0 : 0;
        pairingClone.opponent = store.getters.getTournament.participants.find(participant => participant.id === (pairingClone.color === "white" ? pairingClone.black_player_id : pairingClone.white_player_id));
      }

      if (!pairing || !pairingClone.opponent) {
        pairingClone.color = null;
        pairingClone.resultScore = pairingClone.result === "exempt" || pairingClone.result === "full_bye" ? 1 : pairingClone.result === "half_bye" ? 0.5 : 0
        pairingClone.opponent = {
          first_name: "Bye",
          last_name: "",
        };
      }
      pairingClone.cumulativeScore = previousScore + pairingClone.resultScore;
      pairingClone.roundIndex = round.index;

      previousScore = pairingClone.cumulativeScore;
      return pairingClone;
    }).filter(Boolean);
  });
</script>

<template>
  <div class="overflow-auto">
    <table class="min-w-full text-sm font-light text-start">
      <thead class="border-b border-neutral-200 font-medium">
        <tr>
          <th scope="col" class="text-start py-1" v-t="'Round'"></th>
          <th scope="col" class="text-start py-1" v-t="'tournament.table.opponent'"></th>
          <th scope="col" class="text-start py-1" v-t="'rating'"></th>
          <th scope="col" class="text-start py-1" v-t="'color'"></th>
          <th scope="col" class="text-start py-1" v-t="'result'"></th>
          <th scope="col" class="text-start py-1" v-t="'score'"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(pairing) in selfPairings"
        :key="pairing.id"
        class="border-b border-neutral-200">
          <td class="whitespace-nowrap px-1 py-4 font-medium">{{ pairing.roundIndex }}</td>
          <td v-if="pairing.opponent" class="whitespace-nowrap">{{ pairing.opponent.first_name }} {{ pairing.opponent.last_name }}</td>
          <td v-else class="whitespace-nowrap">--</td>
          <td class="whitespace-nowrap">{{ pairing.opponent?.elo_standard ? elo(pairing.opponent, store.getters.getTournament.format) : "--" }}</td>
          <td class="whitespace-nowrap">{{ pairing.color ? $t(pairing.color) : "--" }}</td>
          <td class="whitespace-nowrap">{{ pairing.resultScore }}</td>
          <td class="whitespace-nowrap">{{ pairing.cumulativeScore }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
