<script setup>
  import moment from 'moment';

  const props = defineProps(['mapped_data', 'user']);
  const emit = defineEmits(['deleteTournament']);
</script>

<template>
  <div class="flex flex-wrap gap-3 sm:gap-4 justify-between items-center bg-gradient-to-r from-gray-600 to-transparent hover:bg-dark-greyblue rounded-lg p-3 border border-gray-400 shadow-md my-1 hover:cursor-pointer">
    <p class="grow sm:w-auto text-lg underline underline-offset-1">{{ mapped_data.tournament.title }}</p>
    <div class="flex items-center gap-1 justify-start min-w-28">
      <i class="fa-solid fa-users"></i>
      <p>{{ mapped_data.nb_participants }}{{ `${mapped_data.tournament.status !== "over" ? "/" + mapped_data.tournament.max_participants : ""}` }}</p>
    </div>
    <div class="flex items-center gap-1 justify-end min-w-24">
      <i class="fa-regular fa-calendar"></i>
      <p>{{ moment(mapped_data.tournament.date).format('DD/MM/YYYY') }}</p>
    </div>
    <div class="flex items-center gap-1 justify-end min-w-24">
      <i class="fa-regular fa-clock"></i>
      <p>{{ moment(mapped_data.tournament.date).format('hh:mm A') }}</p>
    </div>
    <div class="w-4">
      <button v-if="user && mapped_data.tournament.admin_id == user.id"
      class="text-danger" @click="emit('deleteTournament', mapped_data.tournament, $event)" label="Delete">
        <i class="fa-solid fa-trash-can"></i>
      </button>
    </div>
  </div>
</template>
