<script setup>
  import { ref, computed, onMounted } from 'vue';
  import axios from 'axios';

  const props = defineProps({
    redirect: String,
  })
  const redirectLogin = computed(() => {
    return props.redirect ? { name: 'login', query: { redirect: props.redirect }} : { name: 'login' }
  })
  const redirectConfirmationInstructions = computed(() => {
    return props.redirect ? { name: 'confirmationInstructions', query: { redirect: props.redirect }} : { name: 'confirmationInstructions' }
  })

  const email = ref('');
  const emailInput = ref(null)
  const fieldErrors = ref(null);
  const isLoading = ref(false)
  const successMessage = ref(null);

  async function handlePasswordReset() {
    isLoading.value = true;
    fieldErrors.value = null;
    successMessage.value = null;

    axios
      .post('/users/password', {
        user: {
          email: email.value,
        }
      }, {
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json',
        }
      })
      .then((response) => {
        email.value = '';
        successMessage.value = "An email has been send to you to reset your password";
      })
      .catch((err) => {
        fieldErrors.value = err.response?.data?.errors || "An unexpected error occured";
      })
      .finally(() => {
        isLoading.value = false;
      })
  }

  onMounted(() => {
    emailInput.value.focus();
  })
</script>

<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-light-grey">{{ $t('users.resetPasswordTitle') }}</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div v-if="fieldErrors" class="mb-4 p-2 border border-red-900 bg-danger text-center rounded-md">
        <p class="text-md text-light-grey">{{ fieldErrors.email ? `Email ${fieldErrors.email[0]}` : fieldErrors }}</p>
      </div>
      <div v-if="successMessage" class="mb-4 p-2 border border-green-900 bg-success text-center rounded-md">
        <p class="text-md text-light-grey">{{ successMessage }}</p>
      </div>
      <form @submit.prevent="handlePasswordReset"
      class="space-y-6" action="#" method="POST">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('email') }}</label>
          <div class="mt-2">
            <input ref="emailInput" v-model="email" id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" autofocus>
          </div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center primary-btn">{{ $t('users.resetPasswordTitle') }}</button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        {{ $t('users.loginCatchPhrase') }}
        <router-link :to="redirectLogin" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">{{ $t('users.login') }}</router-link>
      </p>
      <p class="mt-4 text-center text-sm text-gray-500">
        {{ $t('users.confirmationInstructionsCatchPhrase') }}
        <router-link :to="redirectConfirmationInstructions" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">{{ $t('users.confirmationInstructionsAction') }}</router-link>
      </p>
    </div>
  </div>
</template>
