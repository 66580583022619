<script setup>
  import { ref, watch, onMounted, onUnmounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { createConsumer } from "@rails/actioncable";
  import api from "../entrypoints/api.js";

  // Subcomponents
  import TournamentShowInfos from "./TournamentShowInfos.vue";
  import TournamentShowTable from "./TournamentShowTable.vue";
  import TournamentShowRounds from './TournamentShowRounds.vue';
  import TournamentShowStandings from "./TournamentShowStandings.vue";

  // Core
  const router = useRouter();
  const store = useStore();

  const tournamentId = router.currentRoute.value.params.id;
  const loading = ref(false);
  let channel = null;

  const visibleTab = ref('infos');

  function setVisibleTab() {
    const tabIndex = location.href.split('#')[1]
    if (tabIndex) {
      visibleTab.value = tabIndex;
    } else {
      visibleTab.value = 'infos';
    }
  }

  function togglePanel(panelKey) {
    visibleTab.value = panelKey;
  }

  watch(visibleTab, () => {
    const new_url = `${location.href.split("#")[0]}#${visibleTab.value}`;
    history.replaceState(null, '', new_url);
  });

  function updateTournamentData(tournamentData) {
    store.dispatch('updateTournamentContext', tournamentData);
  }

  async function initTournament() {
    setVisibleTab();
    loading.value = true;

    // Protect channel subscription in case of tournament update fail
    const response = await api.getTournament(tournamentId);

    if (response.error.value?.response.status === 404) {
      router.push({ name: "NotFound" })
    } else if (response.error.value) {
      console.error(response.error.value);
    }
    updateTournamentData(response.data.value.tournament);
    loading.value = false;
  }
  initTournament()

  onMounted(() => {
    let channelName = "TournamentChannel"
    // if (store.getters.isAuthenticated && store.getters.getUser?.id === store.getters.getTournament?.admin.id) {
    //   channelName = "AdminChannel"
    // }

    // Action cable setup
    channel = createConsumer().subscriptions.create(
      { channel: channelName, tournament_id: tournamentId, auth_token: store.getters.getAuthToken },
      {
        received: data => updateTournamentData(data.tournament),
      }
    );
  })

  onUnmounted(() => {
    if (channel) {
      channel.unsubscribe();
    }
  })
</script>

<template>
  <div class="flex flex-col items-center">
    <div class="fixed bottom-0 bg-deep-greyblue w-screen z-20 rounded-t-3xl border-danger border-t-3 flex gap-3 justify-between px-3 py-3 m-0">
      <button class="align-middle text-center w-1/4 hover:bg-greyblue rounded-xl" href="#infos"
      :class="{ 'active': visibleTab === 'infos' }"
      @click="togglePanel('infos')">
        <div class="text-3xl">
          <i class="fa-solid fa-bullhorn"></i>
        </div>
        <p class="text-md md:text-lg m-0">{{ $t("Infos") }}</p>
      </button>

      <button class="align-middle text-center w-1/4 hover:bg-greyblue rounded-xl" href="#table"
      :class="{ 'active': visibleTab === 'table' }"
      @click="togglePanel('table')">
        <div class="text-3xl">
          <i class="fa-solid fa-chess-board"></i>
        </div>
        <p class="text-md md:text-lg m-0">{{ $t("tabTable") }}</p>
      </button>

      <button class="align-middle text-center w-1/4 hover:bg-greyblue rounded-xl" href="#rounds"
      :class="{ 'active': visibleTab === 'rounds' }"
      @click="togglePanel('rounds')">
        <div class="text-3xl">
          <i class="fa-solid fa-circle-notch"></i>
        </div>
        <p class="text-md md:text-lg m-0">{{ $t("tabRounds") }}</p>
      </button>

      <button class="align-middle text-center h-20 w-1/4 hover:bg-greyblue rounded-xl" href="#standings"
      :class="{ 'active': visibleTab === 'standings' }"
      @click="togglePanel('standings')">
        <div class="text-3xl">
          <i class="fa-solid fa-trophy h-full"></i>
        </div>
        <p class="text-md md:text-lg m-0">{{ $t("tabStandings") }}</p>
      </button>
    </div>

    <div class="m-3 w-11/12"
    v-if="store.getters.getTournament">
      <!-- Overall panel -->
      <div v-show="visibleTab === 'infos'">
        <TournamentShowInfos />
      </div>

      <!-- Player table -->
      <div v-show="visibleTab === 'table'">
        <TournamentShowTable />
      </div>

      <!-- Rounds -->
      <div v-show="visibleTab === 'rounds'">
        <TournamentShowRounds />
      </div>

      <!-- Standings -->
      <div v-show="visibleTab === 'standings'">
        <TournamentShowStandings />
      </div>
    </div>
    <div v-else-if="loading">
      <h1 class="mt-5">{{ $t('loading') }}...</h1>
    </div>
    <div v-else>
      <h1 class="text-2xl mt-5">{{ $t('errors.unexpectedError') }}</h1>
    </div>
  </div>
</template>
