<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import api from "../entrypoints/api.js";

  import PlayerPairings from './PlayerPairings.vue';
  import TournamentShowTableResult from './TournamentShowTableResult.vue';

  const store = useStore();

  const round = computed(() => store.getters.getCurrentRound)
  const pairing = computed(() => store.getters.getUserCurrentPairing)
  const white_player = computed(() => store.getters.getTournament.participants.find(participant => { return participant.id === pairing.value.white_player_id }))
  const black_player = computed(() => store.getters.getTournament.participants.find(participant => { return participant.id === pairing.value.black_player_id }))
  const isAuthenticated = computed(() => store.getters.isAuthenticated)

  const whiteActiveColor = computed(() => {
    return {
      'border-black': white_player.value != store.getters.getUserParticipant,
      'shadow-success border-success': white_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
      'shadow-warning border-warning': white_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && !store.getters.getUserParticipant.active,
      'shadow-danger border-danger': white_player.value === store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin,
    }
  })
  const blackActiveColor = computed(() => {
    return {
      'border-gray-200': black_player.value != store.getters.getUserParticipant,
      'shadow-success border-success': black_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
      'shadow-warning border-warning': black_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && !store.getters.getUserParticipant.active,
      'shadow-danger border-danger': black_player.value === store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin,
    }
  })

  async function toggleParticipantActivation(participant) {
    const response = await api.toggleParticipantActivation(participant);

    if(response.error.value) {
      toast.add({ severity: "error", summary: response.error.value.response.data.message, life: 3000 });
    }
  }
</script>

<template>
  <div>
    <div v-if="store.getters.getTournament.status != 'over'" class="bg-greyblue rounded-xl p-3 text-center flex justify-center mb-4">
      <div v-if="round && pairing" class="flex flex-col items-center text-center w-full">
        <div class="border-b border-neutral-200 font-medium w-full text-center">
          <h1 class="text-3xl mb-2">{{ $t('table') }} {{ pairing.table }}</h1>
          <h6 class="text-lg">{{ $t('Round') }} {{ round.index }}</h6>
        </div>
        <div class="flex flex-col w-full max-w-md items-center">
          <div v-if="pairing.white_player_id"
          class="h-20 w-72 md:my-5 my-2 bg-gray-200 rounded-full text-black shadow-lg border-2"
          :class="whiteActiveColor">
            <div class="text-center">
              <p class="my-0">{{ white_player.first_name || "-" }}</p>
              <p class="my-0">{{ white_player.last_name || "-" }}</p>
            </div>
            <div class="flex justify-center border-t-2 h-7 m-0"
            :class="whiteActiveColor">
              <div class="text-end w-1/5 mr-4">
                <p class="m-0">{{ pairing.white_score }}</p>
              </div>
              <div class="text-center w-3/5 border-x-2 my-0" :class="whiteActiveColor">
                <p>{{ store.getters.getTournament.tie_breaker_1 ? pairing.white_tie_breaker_1 : "" }}{{ store.getters.getTournament.tie_breaker_2 ? ` / ${pairing.white_tie_breaker_2}` : "" }}{{ store.getters.getTournament.tie_breaker_3 ? ` / ${pairing.white_tie_breaker_3}` : "" }}</p>
              </div>
              <div class="text-start w-1/5 ml-2">
                <p class="m-0">{{ pairing.white_elo }}</p>
              </div>
            </div>
          </div>

          <TournamentShowTableResult />

          <div v-if="pairing.black_player_id"
          class="h-20 w-72 md:my-5 my-2 bg-black rounded-full text-light-grey shadow-lg border-2"
          :class="blackActiveColor">
            <div class="text-center">
              <p class="my-0">{{ black_player.first_name || "-" }}</p>
              <p class="my-0">{{ black_player.last_name || "-" }}</p>
            </div>
            <div class="flex justify-center border-t-2 h-7 m-0"
            :class="blackActiveColor">
              <div class="text-end w-1/5 mr-4">
                <p class="m-0">{{ pairing.black_score }}</p>
              </div>
              <div class="text-center w-3/5 border-x-2 my-0" :class="blackActiveColor">
                <p>{{ store.getters.getTournament.tie_breaker_1 ? pairing.black_tie_breaker_1 : "" }}{{ store.getters.getTournament.tie_breaker_2 ? ` / ${pairing.black_tie_breaker_2}` : "" }}{{ store.getters.getTournament.tie_breaker_3 ? ` / ${pairing.black_tie_breaker_3}` : "" }}</p>
              </div>
              <div class="text-start w-1/5 ml-2">
                <p class="m-0">{{ pairing.black_elo }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col gap-2 flex-1 items-center justify-center">
        <h2 v-if="store.getters.getTournament.status === 'over'" v-t="'tableTournamentOverMessage'" class="text-xl self-center"></h2>
        <div v-else>
          <h2 v-if="round && store.getters.getUserParticipant" v-t="'tableDeactivatedPlayerMessage'" class="text-xl self-center"></h2>
          <h2 v-else-if="!round && store.getters.getUserParticipant" v-t="'tableNoRoundMessage'" class="text-xl"></h2>
          <h2 v-else-if="isAuthenticated" class="self-center text-xl" v-t="'tableUnregisteredPlayerMessage'"></h2>
          <div v-else>
            <h2 class="text-2xl flex-1" v-t="'tableUnidentifiedUserMessage'"></h2>
            <RouterLink :to="{ path: '/login', query: { redirect: $route.fullPath }}">
              <button class="primary-btn mt-4" v-t="'users.login'"></button>
            </RouterLink>
          </div>
          <h2 v-if="store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin" class="text-xl self-center" v-html="$t('tournament.table.checkedOutMessage')"></h2>
          <div v-else-if="store.getters.getUserParticipant && !store.getters.getUserParticipant?.active" class="self-center">
            <p class="text-xl mb-6" v-html="$t('tournament.table.deactivatedMessage')"></p>
            <button @click="toggleParticipantActivation(store.getters.getUserParticipant)" class="success-btn text-lg">{{ $t('tournament.table.activateBtn') }}</button>
          </div>
          <div v-else-if="store.getters.getUserParticipant && store.getters.getUserParticipant?.active" class="self-center">
            <p class="text-xl mb-6" v-html="$t('tournament.table.activatedMessage')"></p>
            <button @click="toggleParticipantActivation(store.getters.getUserParticipant)" class="warning-btn text-lg">{{ $t('tournament.table.deactivateBtn') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="store.getters.getUserParticipant" class="bg-greyblue rounded-xl p-3 justify-center">
      <div class="text-center w-full mb-4">
        <h1 class="text-xl font-bold">{{ $t('tournament.table.opponents') }}</h1>
      </div>
      <PlayerPairings :participant="store.getters.getUserParticipant" />
    </div>
  </div>
</template>
