<script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import axios from 'axios'

  const router = useRouter();
  const route = router.currentRoute.value;

  const password = ref('');
  const passwordInput = ref(null)
  const passwordConfirmation = ref('');
  const token = route.query.reset_password_token;

  const fieldErrors = ref({})
  const isLoading = ref(false)

  function handlePasswordChange() {
    isLoading.value = true;
    fieldErrors.value = {};

    axios
      .put('/users/password', {
        user: {
          reset_password_token: token,
          password: password.value,
          password_confirmation: passwordConfirmation.value,
        }
      })
      .then((response) => {
        router.push('/login');
      })
      .catch((err) => {
        fieldErrors.value = err.response?.data?.errors || {};
      })
      .finally(() => {
        isLoading.value = false;
      })
  };

  onMounted(() => {
    passwordInput.value.focus();
  })
</script>

<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-light-grey">{{ $t('users.changePassword') }}</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <!-- <div v-if="error && !Object.keys(fieldErrors).length" class="text-sm text-red-500">{{ error }}</div> -->
      <form @submit.prevent="handlePasswordChange"
      class="space-y-6">
        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('users.newPassword') }}</label>
          </div>
          <div class="mt-2">
            <input ref="passwordInput" v-model="password" id="password" name="password" type="password" autocomplete="new-password" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue">
          </div>
          <div v-if="fieldErrors.password" class="text-sm text-red-500">{{ fieldErrors.password[0] }}</div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password_confirmation" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('users.confirmationPassword') }}</label>
          </div>
          <div class="mt-2">
            <input v-model="passwordConfirmation" id="password_confirmation" name="password" type="password" autocomplete="new-password" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue">
          </div>
          <div v-if="fieldErrors.password_confirmation" class="text-sm text-red-500">{{ fieldErrors.password_confirmation[0] }}</div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center primary-btn">{{ $t('users.changePassword') }}</button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        {{ $t('users.rememberPasswordCatchPhrase') }}
        <router-link to="/login" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">{{ $t('users.login') }}</router-link>
      </p>
    </div>
  </div>
</template>
